import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Main from '../components/Nosotros/main'
import History from '../components/Nosotros/history'
import Commitment from '../components/Nosotros/commitment'
import Team from '../components/Nosotros/team'
import BannerTop from "../components/Global/Modules/MediaPush/BannerTop"

const IndexPage = () => (
  <Layout>
    <Seo title="Nosotros" />
    <div className="nosotros">
      <BannerTop page="NOS"/>
      <Main />
      {/* <History />
      <Commitment />
      <Team /> */}
    </div>
  </Layout>
)

export default IndexPage
