import React, { useEffect, useState } from "react"
import Breadcrumbs from "../breadcrumbs"
import AOS from "aos"
import "aos/dist/aos.css"
import { graphql, useStaticQuery } from "gatsby"
import { getBrText } from "../../helpers/helper.rendering"

const Main = () => {

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  const [sliderStep, setSliderStep] = useState(1)


  function getCurrentYear() {
    return new Date().getFullYear().toString().replace("20",'')
  }

  return (
    <section className="main">
      <div className="container-fluid full-fluid">
        <Breadcrumbs props={[
            {name:'Home',route:"/",location:''},
            {name:'Nosotros',route:'/',location:'',active :true}
        ]}/>
        <div className="row">
          <div className="col-lg-3 h-100 d-flex justify-content-center flex-column">
            <img
              className="logo-absolute"
              src="/images/round-logo.svg"
              alt=""
            />
            <h2>
              Quiénes <br />
              somos
            </h2>
            <h5>
              TU MEJOR OPCIÓN  <br />
            </h5>
            <h5>
              <div className="d-flex align-items-center mb-0">
                {" "}
                20 <span className="mx-2 d-inline-block"></span>{getCurrentYear()}{" "}
              </div>
            </h5>
            <h5>
              Te contamos todo lo que necesitas saber <br />
              para trabajar con nosotros. Quiénes somos <br />
              y en lo que somos expertos. <br />
            </h5>
          </div>
          <div className="col-lg-9 ps-lg-0 h-100 h-sm-auto d-none d-lg-block">
            <img
              className={"d-block d-lg-none"}
              src="assets/images/about-1.png"
              alt=""
            />
            <div className="row content-main ms-0 align-items-center justify-content-center">
              <div className="w-30 d-flex align-items-center flex-column">
                {/* <BreadCrumbs
                  props={[
                    { name: "Home", route: settings.base_url +"", location: "" },
                    { name: "Nosotros", route: "", location: "", active: true },
                  ]}
                /> */}
                <div
                  className={
                    "col-lg-12 step  " +
                    (sliderStep != 1 ? "notActive" : "active")
                  }
                >
                  <p>
                    La sociedad surge con el deseo de emprender en la ciudad
                    <br className="d-none d-lg-inline" />
                    un nuevo concepto inmobiliario, en base a la experiencia
                    <br className="d-none d-lg-inline" />
                    vivida de ambos fundadores en el mercado.{" "}
                    <br className="d-none d-lg-inline" />
                    <br className="d-none d-lg-inline" />
                    Priorizando la atención personalizada y con el uso de nuevas
                    <br className="d-none d-lg-inline" />
                    tecnologías, buscamos que nuestros clientes tengan la mejor
                    <br className="d-none d-lg-inline" />
                    experiencia al momento de invertir en un inmueble,
                    simplificando <br className="d-none d-lg-inline" />
                    al máximo la toma de decisiones.{" "}
                    <br className="d-none d-lg-inline" />
                    <br className="d-none d-lg-inline" />
                    Es por eso que al momento de realizar una inversión{" "}
                    <br className="d-none d-lg-inline" /> somos su mejor opción.
                  </p>
                  <img
                    className={
                      "position-absolute absolute-top d-none d-lg-block" +
                      (sliderStep != 1 ? " d-lg-none" : "")
                    }
                    src="/images/about-0.png"
                    alt=""
                  />
                  <img
                    className={
                      "position-absolute absolute-bottom d-none d-lg-block" +
                      (sliderStep != 1 ? " d-lg-none" : "")
                    }
                    src="/images/about-1.png"
                    alt=""
                  />
                  <div className="line-horizontal"></div>
                </div>
                <div
                  className={
                    "col-lg-12 step " +
                    (sliderStep != 2 ? "notActive" : "active")
                  }
                >
                  <div className="card-about">
                    <h3>FRANCISCO LÓPEZ BISOGLIO</h3>
                    <div className="content-img">
                      <img
                        className="absolute-mat"
                        src="/images/mat_fran.png"
                        alt=""
                      />
                      <div className="content-hover">
                        <img src="/images/fran.jpg" alt="" />
                      </div>
                    </div>
                    <div className="w-100">
                      {/* <div className="d-flex align-items-start w-100"> */}
                      <span className="w-100 d-block">CO Founder & Socio</span>
                      <span>Martillero y corredor público</span>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "col-lg-12 step " +
                    (sliderStep != 3 ? "notActive" : "active")
                  }
                >
                  <div className="card-about">
                    <h3>NICOLÁS OYARBIDE</h3>
                    <div className="content-img">
                      <img
                        className="absolute-mat"
                        src="/images/mat_nico.png"
                        alt=""
                      />
                      <div className="content-hover">
                        <img src="/images/nico.jpg" alt="" />
                      </div>
                    </div>
                    <div className="w-100">
                      <span className="w-100 d-block">CO Founder & Socio</span>
                      <span>Martillero y corredor público</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12"></div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 offset-lg-3 d-none d-lg-block">
            <div className="buttons">
              <button
                onClick={() => setSliderStep(1)}
                className={sliderStep == 1 ? "bold" : ""}
              >
                01
              </button>
              <button
                onClick={() => setSliderStep(2)}
                className={sliderStep == 2 ? "bold" : ""}
              >
                02
              </button>
              <button
                onClick={() => setSliderStep(3)}
                className={sliderStep == 3 ? "bold" : ""}
              >
                03
              </button>
            </div>
          </div>
          <div className="col-12 d-block d-lg-none about_info position-relative">
            <img
              src=".//images/about-mobile.png"
              alt=""
              className="main-photo d-inline-block"
            />
            <h5>INMOBILIARIA BOUTIQUE</h5>
            <p>
              La sociedad surge con el deseo de emprender en la ciudad un nuevo
              concepto inmobiliario, en base a la experiencia vivida de ambos
              fundadores en el mercado. Priorizando la atención personalizada y
              con el uso de nuevas tecnologías, buscamos que nuestros clientes
              tengan la mejor experiencia al momento de invertir en un inmueble,
              simplificando al máximo la toma de decisiones. Es por eso que al
              momento de realizar una inversión somos su mejor opción.
            </p>
            <div className="line-horizontal"></div>
            <div className="card-about">
              <h3>FRANCISCO LÓPEZ BISOGLIO</h3>
              <div className="content-img">
                <img
                  className="absolute-mat"
                  src="/images/mat_fran.png"
                  alt=""
                />
                {/* <div className="content-hover"> */}
                <img src="/images/fran.jpg" alt="" />
                {/* </div> */}
              </div>
              <div className="w-100 mb-4">
                {/* <div className="d-flex align-items-center text-center justify-content-center w-100"> */}
                <span className="d-block w-100 mb-0">CO Founder & Socio</span>
                <span>Martillero y corredor público</span>
              </div>
            </div>
            <div className="card-about">
              <h3>NICOLÁS OYARBIDE</h3>
              <div className="content-img">
                <img
                  className="absolute-mat"
                  src="/images/mat_nico.png"
                  alt=""
                />
                {/* <div className="content-hover"> */}
                <img src="assets/images/nico.jpg" alt="" />
                {/* </div> */}
              </div>
              <div className="mb-4 w-100">
                <span className="d-block w-100 mb-0">CO Founder & Socio</span>
                <span>Martillero y corredor público</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-between index-1">
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
